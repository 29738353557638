.contacts{
    color: black;
    background-color: #efefef;
    max-width: 1280px;
    padding: 37px 104px 95px;
    
}

.contacts__title{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    padding-bottom: 23px;
    border-bottom: solid 1px #3F3F3F;
}

.contacts__grid{
    display: grid;
    grid-template-columns: repeat(2, 330px);
    justify-content: center;
    gap: 100px;
    margin-top: 75px;
}

.contacts__element{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contact__img{
    padding-bottom: 40px;
    height: 60px;
    width: 80px;
}

.contacts__link{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.contacts__subtitle{
    font-size: 20px;
}

.contacts__link:hover{
    opacity: 0.6;
}

@media screen and (max-width: 800px) {
    .contacts__grid{
        display: grid;
        grid-template-columns: repeat(1, 330px);
        justify-content: center;
        gap: 120px;
        margin-top: 75px;
    } 
    
    .contacts{
        color: black;
        background-color: #efefef;
        max-width: 1280px;
        padding: 37px 50px 95px;
        
    }
}

@media screen and (max-width: 500px) {
    .contacts__title{
        font-size: 20px;
        line-height: 25px;
    }
}