.header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 18px 70px;
    max-width: 1280px;
}

.header__logo{
    padding: 0;
    padding-right: 0;
    margin-right: 100px;
    width: 160px;
    height: 152px;
}

@media screen and (max-width: 450px) {
    .header {
        padding: 18px 14px;
    }

    .header__logo{
        width: 100px;
        height: 95px;
    }
}
