.benefits{
    color: black;
    background-color: white;
    max-width: 1280px;
    padding: 37px 104px 45px;
    text-align: center;
    flex: 1 0 auto;
}

.benefits__title{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    padding-bottom: 23px;
    border-bottom: solid 1px #3F3F3F;
}

.benefits__grid{
    display: grid;
    grid-template-columns: repeat(3, minmax(230px, 1fr)); 
    grid-column-gap: 28px;
    grid-row-gap: 32px;
    padding-top: 55px;
    justify-content: center;
    
}

.benefits__element{
    justify-content: space-between;
}

.benefits__img{
    width: 268px;
    height: 252px;
    border: solid 1px #A40000;
    border-radius: 50px;
}

.benefits__subtitle{
    margin: 0 20px 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}

 @media screen and (max-width: 1000px) {
    .benefits__grid{
        grid-template-columns: repeat(2, minmax(230px, 1fr));
        grid-column-gap: 24px;
        grid-row-gap: 35px;
        padding: 70px 30px 30px;
    }

    .benefits__img{
        width: 218px;
        height: 204px;
    }

    .benefits{
        padding: 37px 50px 40px;
        flex: 1 0 auto;
    }
}

@media screen and (max-width: 550px) {
    .benefits__grid{
        grid-template-columns: repeat(1, 230px);
        grid-column-gap: 24px;
        grid-row-gap: 25px;
        padding: 20px 30px 30px;
    }

    .benefits__title{
        font-size: 20px;
        line-height: 25px;
    }

    .benefits__img{
        width: 188px;
        height: 175px;
    }
}