.buy{
    color: black;
    background-color: #efefef;
    max-width: 1280px;
    padding: 37px 104px 95px;
    
}

.buy__title{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    padding-bottom: 23px;
    border-bottom: solid 1px #3F3F3F;
}

.buy__grid{
    display: grid;
    grid-template-columns: repeat(1, 330px);
    justify-content: center;
    gap: 120px;
    margin-top: 75px;
}

.buy__element{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.buy__img{
    padding-bottom: 40px;
}

.buy__link{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.buy__link:hover{
    opacity: 0.6;
}

@media screen and (max-width: 800px) {
    .buy__grid{
        display: grid;
        grid-template-columns: repeat(1, 330px);
        justify-content: center;
        gap: 120px;
        margin-top: 75px;
    }
    
    .buy{
        color: black;
        background-color: #efefef;
        max-width: 1280px;
        padding: 37px 50px 95px;
        
    }
}

@media screen and (max-width: 500px) {
    .buy__title{
        font-size: 20px;
        line-height: 25px;
    }
}