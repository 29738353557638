.promo{
    max-width: 1280px;
    min-width: 320px;
    color: rgba(255, 255, 255, 1);
    padding: 75px 70px;
    display: flex;
    flex-direction: column;
    align-content:flex-start;
    background-color:  #3F3F3F;
    background-image: url(../../image/emu2.png);
    background-repeat: no-repeat;
    background-position: right 70px ;
    background-size: 812px 508.29px ;
    
}

.promo__text{
    margin: 0;
    padding: 0;
}

.promo__title{
    text-align: left;
    font-weight: 700;
    font-size: 82px;
    line-height: 90px;
    max-width: 600px;
    margin-bottom: 40px;
}

.promo__subtitle{
    width: 310px;
    margin-top: 36px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    mix-blend-mode: normal;
    opacity: 0.5;
}

.promo__button{
    margin-top: 156px;
    width: 129px;
    height: 36px;
    background-color: transparent;
    opacity: 0.6;
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: #FFFFFF;
}

.promo__button:hover{
    cursor: pointer;
    opacity: .4;
}

.promo__button_link{
    color: #FFFFFF;
    text-decoration: none;
}

@media screen and (max-width: 800px) {
    .promo{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 492px 0 76px;
        background-size: 591px 369.95px;
        background-position: center top 50px;
    }
    
    .promo__title{
        margin: 0 44px;
        font-size: 40px;
        line-height: 50px;
        max-width: 700px;
        text-align: center;
    }

    .promo__subtitle{
        width: 260px;
        /* height: 32px; */
        margin: 22px auto 92px;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
    }

    .promo__button{
        margin: auto;
    }
}


@media screen and (max-width: 500px) {
    .promo{
        padding: 320px 0 30px;
        background-position: center top 70px;
        background-size: 322.21px 201.69px;
    }

    .promo__title{
        margin: 0 10px;
        font-size: 30px;
        line-height: 38px;
        max-width: 300px;
    }

    .promo__subtitle{
        width: 260px;
        margin: 24px 30px 80px;
        font-size: 12px;
    }

    .promo__button{
        margin: 0 95px;
    }
}