.header__navigation{
    width: 93%;
    margin-top: 50px;
    justify-content: space-between;
}

.navigation__menu {
    display: none;
    margin-left: auto;
    padding: 0;
    width: 40px;
    height: 43px;
    outline: none;
    border: none;
    background: transparent url('../../image/menu-burger.svg') center center / cover no-repeat;
    transition: opacity .2s linear;
  }
  
  .navigation__menu:hover {
    opacity: .6;
    cursor: pointer;
  }
  
  .navigation__sidebar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .navigation__container-list{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .navigation__close{
    display: none;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    outline: none;
    border: none;
    background: transparent url('../../image/close_menu.svg') center center / 32px no-repeat;
    align-self: flex-end;
    transition: opacity .2s linear;
  }
  
  .navigation__close:hover {
    opacity: .6;
    cursor: pointer;
  }
  
  .navigation__list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    list-style: none;
  }
  
  .navigation__list-item {
    margin: 0 0 20px 0;
  }

  .navigation__list-item:nth-child(2) {
    margin-right: 20px;
  }

  .navigation__list-item:nth-child(1) {
    margin-right: 20px;
  }
  
  .navigation__list-item_type_main {
    display: none;
  }
  
  .navigation__link {
    color: #000;
    font-size: 20px;
    color: white;
    font-weight: 400;
    line-height: 18px;
    text-decoration: none;
    transition: opacity .2s linear;
  }
  
  .navigation__link:hover {
    opacity: .6;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .navigation__menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  
    .navigation__container {
      display: none;
    }
  
    .navigation__container_visible {
      display: flex;
      position: fixed;
      width: 100%;
      top: 0;
      right: 0;
      background-color: rgba(0, 0, 0, .3);
      z-index: 2;
    }
  
    .navigation__sidebar {
      box-sizing: border-box;
      width: 520px;
      min-height: 100vh;
      margin-left: auto;
      flex-direction: column;
      justify-content: flex-start;
      padding: 27px 27px 90px;
      background-color: #3F3F3F;
    }
  
    .navigation__container-list{
      width: 100%;
    }
  
    .navigation__close {
      display: flex;
    }
  
    .navigation__list {
      flex-direction: column;
      margin-right: 0;
      margin: 110px auto 0;
      align-items: center;
    }
  
    .navigation__list-item {
        align-items: center;
        justify-content: center;
      margin: 0 0 24px 0;
    }
  
    .navigation__list-item_type_main {
      display: flex;
    }
  
    .navigation__link {
      padding: 0 0 4px;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-decoration: none;
    }
  }
  
  @media screen and (max-width: 450px) {
    .header__navigation{
        margin-top: 30px;
    }

    .navigation__list {
      margin: 105px auto 0;
    }
  
    .navigation__sidebar {
      padding: 18px 18px 46px;
    }
  
    .navigation__list-item {
      margin: 0 0 20px 0;
    }
  }