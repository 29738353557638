.emulators{
    flex: 1 0 auto;
    color: black;
    background-color: white;
    max-width: 1280px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    margin-bottom: 61px;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.emulatrs__title{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    padding-bottom: 23px;
    border-bottom: solid 1px #3F3F3F;
    text-align: center;
    margin: 37px 104px 0;
}

.emulators__grid{
    grid-template-columns: repeat(2,minmax(min-content, 440px));
    column-gap: 40px;
    max-width: 1280px;
    justify-content: center;
    justify-items: center;
    padding-bottom: 30px;
    margin-left: 10%;
    margin-right: 20px;
}

.emuiator__grupo{
    padding-bottom: 30px;
    border-bottom: solid 1px #b8b8b8;
}

.emuiator__grupo:last-child{
    border-bottom: none;
}

.emulator__element{
      grid-template-columns: repeat(2,minmax(min-content, 440px));
      justify-content: center;
     font-size: 22px;
    display: grid;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px ;
}

.emulator__title{
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 27px;
    padding: 0;
    text-align: center;
    z-index: 999;
    margin-top: 30px;
    /* cursor: pointer;   */
}

.emulator__about_element{
    width: 83px;
    height: 83px;
}

.emulator__img{
    width: 100%;
    margin-top: 140px;
    position: relative;
}

.emulator__prices{
    padding-top: 40px;
    font-size: 16px;
    display: flex;
    justify-content: center;
}

.emulator__about{
    display: flex;
    margin-top: 40px;
    margin-left: 0;
    justify-content: left;
    position: absolute;
    left: 20%;
}

.emulator__about_element{
    padding-right: 20px;
    width: 100%;
}

.emulator__about_element:last-child{
    padding-right: 0;
}

.emulator__about_tres{
    margin-left: 200px; 
}

.emulator__about_dos{
    margin-left: 250px; 
}

.emulator__imgabout{
    width: 100%;
}

@media screen and (max-width: 1100px) {
    .emulator__about{
        left: 10%;
    }

    .emulator__about_element{
        padding-right: 5px;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .emulators__grid{
       display: flex;
       flex-direction: column;
        row-gap: 72px;
        padding: 0;
        margin: 30px 20px 90px;

    }

    .emuiator__grupo{
        grid-column: 2/3;
        display: grid;
        grid-template-columns: repeat(1,minmax(min-content, 440px));
        grid-template-rows: repeat(2,minmax(min-content, 100px));
        max-width: 720px;
        gap: 48px 40px;
        justify-content: center;
        align-items: flex-start;
    }

    .emulator__element{
        grid-template-columns: repeat(1,minmax(min-content, 440px));
        grid-template-rows: repeat(2,minmax(min-content, 100px));
        margin: auto;
        justify-content: center;
    }

    .emulator__img{
        margin-top: 0;
        margin: auto;
        margin-top: 30px;
    }

    .emulator__about{
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin: 0;
        margin-left: 150px;
        justify-content: left;
        position: absolute;
        margin-top: 70px;
    }

    .emulator__title{
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 27px;
        padding: 0;
        text-align: center;
        z-index: 999;
        margin: 0;
    }
    
}

@media screen and (max-width: 575px) {

    .emulators__grid{
         margin: 20px 5px 40px;
 
     }

    .emulatrs__title{
        margin: 10px 25px 0;
    }

    .emulator__about{
        margin-top: 70px;
        margin-left: 0;
        left: 10%;
    }

    .emulator__about_element{
        padding-right: 5px;
    }
}

@media screen and (max-width: 400px) {
    .emulator__title{
        font-size: 20px;
        line-height: 25px;
    }


    .emulator__about_element{
        padding-right: 5px;
        width: 65px;
        height: 65px;

    }
}