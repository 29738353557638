.page {
    max-width: 1280px;
    min-width: 320px;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    background-color: #3F3F3F;
}